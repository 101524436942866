import React, { useEffect, useRef, useState } from "react";
import api from "../../config/configApi";
import "./Veiculo.scss";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Cards from "../../components/Cards/Cards";

import NotFound from "../NotFound";

function Veiculo() {
    const { id } = useParams();
    const sliderRef = useRef(null);
    const urlAtual = window.location.href;
    const [veiculo, setVeiculo] = useState({
        AnoFabricacao: "",
        AnoModelo: "",
        Cambio: "",
        Carroceria: "",
        Combustivel: "",
        Cor: "",
        CreatedAt: "",
        Direcao: "",
        IdCategoria: "",
        IdMarca: "",
        IdVeiculo: "",
        Km: "",
        Modelo: "",
        NomeCategoria: "",
        NomeMarca: "",
        Observacao: "",
        Tipo: "",
        Tracao: "",
        Valor: "",
        Pasta: ""
    });
    const [vehicles, setVehicles] = useState({
        AnoFabricacao: "",
        AnoModelo: "",
        Cambio: "",
        Carroceria: "",
        Combustivel: "",
        Cor: "",
        CreatedAt: "",
        Direcao: "",
        IdCategoria: "",
        IdMarca: "",
        IdVeiculo: "",
        IdView: "",
        Imagem: "",
        Km: "",
        Modelo: "",
        NomeMarca: "",
        Observacao: "",
        Tipo: "",
        Tracao: "",
        Valor: ""
    });
    const [loading, setLoading] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [photos, setPhotos] = useState([]);

    // USEEFFECT PARA UM UNICO VEICULO
    useEffect(() => {
        // PEGA UM UNICO VEICULO
        const getVeiculo = async () => {
            try {
                const response = await api.get(`/api/veiculos/getVeiculo/${id}`);
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }

        const getPhotos = async (img) => {
            try {
                const response = await Photos.getAll(img);
                return response;
            } catch (err) {
                console.log(err);
            }
        }

        // PEGA VARIAS VEICULOS PARA LISTA DE RELACIONADOS
        const pegarVeiculos = async () => {
            try {
                const response = await api.get(`/api/veiculos/getRandom/${id}`);
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }

        const fetchBoth = async () => {
            setLoading(true);
            const vehicle = await getVeiculo();
            const pic = await getPhotos(vehicle.Pasta);
            setVeiculo(vehicle);
            setPhotos(pic);
            setLoading(false);
            setWaiting(true);
            const veiculos = await pegarVeiculos();
            setVehicles(veiculos);
            setWaiting(false);
        }

        fetchBoth();
    }, []);

    function transformKm() {
        let km = veiculo.Km.toString();

        if (km == 1) {
            km = "Consulte";
            return km;
        } else {
            if (km.length === 7) {
                const a = km.slice(0, 1);
                const b = km.slice(1, 4);
                const c = km.slice(4, km.length + 1);
                km = a.concat(".", b, ".", c);
            }

            if (km.length === 6) {
                const a = km.slice(0, 3);
                const b = km.slice(3, km.length + 1);
                km = a.concat(".", b);
            }

            if (km.length === 5) {
                const a = km.slice(0, 2);
                const b = km.slice(2, km.length + 1);
                km = a.concat(".", b);
            }
            return km + "km";
        }
    }

    function transformPrice() {
        let price = veiculo.Valor.toString();

        if (price == 1) {
            price = "Consulte";
            return price;
        } else {
            if (price.length === 6) {
                const a = price.slice(0, 3);
                const b = price.slice(3, price.length + 1);
                price = a.concat(".", b);
            }

            if (price.length === 5) {
                const a = price.slice(0, 2);
                const b = price.slice(2, price.length + 1);
                price = a.concat(".", b);
            }
            return "R$ " + price + ",00";
        }
    }

    function transformObs() {
        let obs = veiculo.Observacao;
        obs = obs.replace(/(\r\n|\n\r|\r|\n)/g, "<br />");

        return { __html: obs };
    }

    function Relacionados() {
        return (
            <div className="relacionados">
                <div className="relacionados__container">
                    <div className="relacionados__content">
                        <h3 className="relacionados__title">Relacionados</h3>
                        <Link to="/estoque" onClick={() => window.scroll(0, 0)} className="relacionados__link">Ver mais</Link>
                    </div>
                </div>
                {waiting &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }}>
                        <img src={spinner} alt="Loading..." style={{ width: "50px", height: "50px" }} />
                    </div>
                }

                {!waiting && vehicles.length > 0 &&
                    <section className="cards">
                        {vehicles.map(item => (
                            <Cards key={item.IdVeiculo} {...item} />
                        ))}
                    </section>
                }

                {!waiting && vehicles.length === 0 &&
                    <p style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }}>Nenhum registro relacionado encontrado!!!</p>
                }
            </div>
        )
    }

    if (!loading && photos.length > 0) {
        const settings = {
            customPaging: function (i) {
                return (
                    <a>
                        <img
                            src={photos[0 + i].url}
                            alt="uploaded"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "/default-thumb.png";
                            }}
                        />
                    </a>
                );
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: photos.length > 3,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        if (veiculo.IdCategoria === 1) {
            return (
                <div className="vehicle">
                    <div className="vehicle__container">
                        <div className="vehicle__content">
                            <div className="vehicle__left">

                                <button
                                    className="arrow--prev"
                                    onClick={() => { sliderRef.current.slickPrev(); }}
                                >
                                    <RiArrowLeftSLine className="arrow--icon" />
                                </button>
                                <button
                                    className="arrow--next"
                                    onClick={() => { sliderRef.current.slickNext(); }}
                                >
                                    <RiArrowRightSLine className="arrow--icon" />
                                </button>

                                <Slider {...settings}
                                    ref={sliderRef}
                                >
                                    {photos.map((item, index) => (
                                        <div key={index} className="vehicle__image">
                                            <img
                                                src={item.url}
                                                alt={veiculo.Modelo}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "/default-thumb.png";
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className="vehicle__right">
                                <div className="right__content">
                                    <h2>{veiculo.NomeMarca} {veiculo.Modelo} {veiculo.Tracao}</h2>
                                    <h2>{veiculo.AnoFabricacao}/{veiculo.AnoModelo} {veiculo.Cambio}</h2>
                                    <h1>{transformPrice()}</h1>
                                    <span className="border__details"></span>

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="detail__title">Marca</td>
                                                <td>{veiculo.NomeMarca}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Modelo</td>
                                                <td>{veiculo.Modelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Ano</td>
                                                <td>{veiculo.AnoFabricacao}/{veiculo.AnoModelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">KM</td>
                                                <td>{transformKm()}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Carroceria</td>
                                                <td>{veiculo.Carroceria}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Câmbio</td>
                                                <td>{veiculo.Cambio}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Direção</td>
                                                <td>{veiculo.Direcao}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Combustível</td>
                                                <td>{veiculo.Combustivel}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Cor</td>
                                                <td>{veiculo.Cor}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="info__title">Departamento de vendas</h3>
                                    <div className="btn__group">
                                        <p className="btn telefone">
                                            <BsFillTelephoneFill />(66) 99981-5612
                                        </p>
                                        <Link
                                            to={`https://wa.me/+5566999815612?text=Olá, gostaria de saber mais informações sobre ${veiculo.NomeMarca} ${veiculo.Modelo} ${veiculo.AnoFabricacao}/${veiculo.AnoModelo} no valor de ${transformPrice()} ${urlAtual}`}
                                            target="_blank"
                                            className="btn whatsapp"
                                        >
                                            <FaWhatsapp />WhatsApp
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vehicle__bottom">
                            <h3 className="info__title" style={{ marginTop: 0 }}>Observações</h3>
                            <span className="border__details"></span>
                            <p className="observacao" dangerouslySetInnerHTML={transformObs()}></p>
                        </div>
                    </div>

                    <Relacionados />

                </div>
            )
        } else if (veiculo.IdCategoria === 2) {
            return (
                <div className="vehicle">
                    <div className="vehicle__container">
                        <div className="vehicle__content">
                            <div className="vehicle__left">

                                <button
                                    className="arrow--prev"
                                    onClick={() => { sliderRef.current.slickPrev(); }}
                                >
                                    <RiArrowLeftSLine className="arrow--icon" />
                                </button>
                                <button
                                    className="arrow--next"
                                    onClick={() => { sliderRef.current.slickNext(); }}
                                >
                                    <RiArrowRightSLine className="arrow--icon" />
                                </button>

                                <Slider {...settings}
                                    ref={sliderRef}
                                >
                                    {photos.map((item, index) => (
                                        <div key={index} className="vehicle__image">
                                            <img
                                                src={item.url}
                                                alt={veiculo.Modelo}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "/default-thumb.png";
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className="vehicle__right">
                                <div className="right__content">
                                    <h2>{veiculo.Tipo} {veiculo.Modelo} {veiculo.NomeMarca}</h2>
                                    <h2>{veiculo.AnoFabricacao}/{veiculo.AnoModelo}</h2>
                                    <h1>{transformPrice()}</h1>
                                    <span className="border__details"></span>

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="detail__title">Marca</td>
                                                <td>{veiculo.NomeMarca}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Modelo</td>
                                                <td>{veiculo.Modelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Ano</td>
                                                <td>{veiculo.AnoFabricacao}/{veiculo.AnoModelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Cor</td>
                                                <td>{veiculo.Cor}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="info__title">Departamento de vendas</h3>
                                    <div className="btn__group">
                                        <p className="btn telefone">
                                            <BsFillTelephoneFill />(66) 99981-5612
                                        </p>
                                        <Link
                                            to={`https://wa.me/+5566999815612?text=Olá, gostaria de saber mais informações sobre ${veiculo.Tipo} ${veiculo.Modelo} ${veiculo.NomeMarca} ${veiculo.AnoFabricacao}/${veiculo.AnoModelo} no valor de ${transformPrice()} ${urlAtual}`}
                                            target="_blank"
                                            className="btn whatsapp"
                                        >
                                            <FaWhatsapp />WhatsApp
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vehicle__bottom">
                            <h3 className="info__title" style={{ marginTop: 0 }}>Observações</h3>
                            <span className="border__details"></span>
                            <p className="observacao" dangerouslySetInnerHTML={transformObs()}></p>
                        </div>
                    </div>
                    <Relacionados />
                </div>
            )
        } else if (veiculo.IdCategoria === 3) {
            return (
                <div className="vehicle">
                    <div className="vehicle__container">
                        <div className="vehicle__content">
                            <div className="vehicle__left">

                                <button
                                    className="arrow--prev"
                                    onClick={() => { sliderRef.current.slickPrev(); }}
                                >
                                    <RiArrowLeftSLine className="arrow--icon" />
                                </button>
                                <button
                                    className="arrow--next"
                                    onClick={() => { sliderRef.current.slickNext(); }}
                                >
                                    <RiArrowRightSLine className="arrow--icon" />
                                </button>

                                <Slider {...settings}
                                    ref={sliderRef}
                                >
                                    {photos.map((item, index) => (
                                        <div key={index} className="vehicle__image">
                                            <img
                                                src={item.url}
                                                alt={veiculo.Modelo}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "/default-thumb.png";
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className="vehicle__right">
                                <div className="right__content">
                                    <h2>{veiculo.NomeMarca} {veiculo.Modelo}</h2>
                                    <h2>{veiculo.AnoFabricacao}/{veiculo.AnoModelo} {veiculo.Cambio}</h2>
                                    <h1>{transformPrice()}</h1>
                                    <span className="border__details"></span>

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="detail__title">Marca</td>
                                                <td>{veiculo.NomeMarca}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Modelo</td>
                                                <td>{veiculo.Modelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Ano</td>
                                                <td>{veiculo.AnoFabricacao}/{veiculo.AnoModelo}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">KM</td>
                                                <td>{transformKm()}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Câmbio</td>
                                                <td>{veiculo.Cambio}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Direção</td>
                                                <td>{veiculo.Direcao}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Combustível</td>
                                                <td>{veiculo.Combustivel}</td>
                                            </tr>

                                            <tr>
                                                <td className="detail__title">Cor</td>
                                                <td>{veiculo.Cor}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="info__title">Departamento de vendas</h3>
                                    <div className="btn__group">
                                        <p className="btn telefone">
                                            <BsFillTelephoneFill />(66) 99981-5612
                                        </p>
                                        <Link
                                            to={`https://wa.me/+5566999815612?text=Olá, gostaria de saber mais informações sobre ${veiculo.NomeMarca} ${veiculo.Modelo} ${veiculo.AnoFabricacao}/${veiculo.AnoModelo} no valor de ${transformPrice()} ${urlAtual}`}
                                            target="_blank"
                                            className="btn whatsapp"
                                        >
                                            <FaWhatsapp />WhatsApp
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vehicle__bottom">
                            <h3 className="info__title" style={{ marginTop: 0 }}>Observações</h3>
                            <span className="border__details"></span>
                            <p className="observacao" dangerouslySetInnerHTML={transformObs()}></p>
                        </div>
                    </div>
                    <Relacionados />
                </div>
            )
        }
    }

    if (!veiculo) {
        return <NotFound />;
    }

    return (
        <>
            {loading &&
                <div style={{ display: "flex", justifyContent: "center", height: "500px", marginTop: "150px", marginBottom: "50px" }}>
                    <img src={spinner} alt="Loading..." style={{ width: "50px", height: "50px" }} />
                </div>
            }
        </>
    )
}

export default Veiculo;
