import React, { useState, useEffect } from "react";
import "../../styles/Cadastro.scss";
import Sidebar from "../../components/Sidebar";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import api from "../../config/configApi";
import { FaRegTrashCan } from "react-icons/fa6";
import { InputNumber } from "primereact/inputnumber";
import { FaFile } from "react-icons/fa";

function DeletarCarro() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [editValues, setEditValues] = useState({
        IdUsuario: "",
        IdVeiculo: "",
        IdCategoria: "",
        NomeCategoria: "",
        IdMarca: "",
        NomeMarca: "",
        AnoFabricacao: "",
        AnoModelo: "",
        Tipo: "",
        Modelo: "",
        Direcao: "",
        Cor: "",
        Combustivel: "",
        Cambio: "",
        Tracao: "",
        Carroceria: "",
        Valor: "",
        Observacao: "",
        Pasta: "",
        Imagem: "",
        CreatedAt: ""
    })
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )

    const [errors, setErrors] = useState({
        msg: null
    });

    // USEEFFECT PARA UM UNICO VEICULO
    useEffect(() => {
        const getVeiculo = async () => {
            try {
                const response = await api.get(`/api/veiculos/pegarVeiculoId/${id}`);
                return response.data;
            } catch (err) {
                console.log(err);
                toast.error(err.response.data);
                localStorage.clear();
                setTimeout(() => navigate("/login"), 3000);
            }
        }

        const getPhotos = async (folder) => {
            try {
                const response = await Photos.getAll(folder);
                return response;
            } catch (err) {
                console.log(err);
            }
        }

        const fetchBoth = async () => {
            setLoading(true);
            const vehicle = await getVeiculo();
            const pic = await getPhotos(vehicle.Pasta);
            setEditValues(vehicle);
            setPhotos(pic);
            setLoading(false);
        }

        fetchBoth();
    }, []);

    const handleClick = async (e) => {
        e.preventDefault();
        e.currentTarget.disabled = true;

        let deuErro;

        await Photos.deletePhotos(editValues.Pasta, photos)
            .then(() => {
                deuErro = false;
            })
            .catch((err) => {
                console.log(err);
                setErrors((prev) => ({ ...prev, msg: JSON.stringify(err) }))
                deuErro = true;
            })

        if (deuErro === false) {
            await api.delete(`/api/veiculos/deleteVeiculo/${editValues.IdVeiculo}`)
                .then(res => {
                    toast.success(res.data.msg);
                    setTimeout(() => navigate(`/area-usuario/${currentUser.IdUsuario}`), 3000);
                })
                .catch(err => {
                    if (err.response) {
                        toast.error(err.response.data);
                        localStorage.clear();
                        setTimeout(() => navigate("/login"), 3000);
                    } else {
                        console.log(err);
                        toast.error("Erro: Tente novamente mais tarde!");
                    }
                })
        }
    }

    return (
        <>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <Sidebar id={currentUser.IdUsuario} />
            <div className="cadastro">
                <div className="cadastro__container">
                    <div className="cadastro__top delete">
                        <h1 className="cadastro__titulo">
                            <span><MdDelete /></span>Excluir carro
                        </h1>
                    </div>
                    {loading &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={spinner} alt="Loading..." />
                        </div>
                    }

                    {!loading &&
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="input__group">
                                    <input
                                        name="Categoria"
                                        value={"CARRO"}
                                        className="input__field disabled"
                                        placeholder="CARRO"
                                        disabled
                                    />

                                    <label htmlFor="Categoria" className="input__label">Categoria</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="IdMarca"
                                        className="input__field disabled"
                                        disabled
                                    >
                                        <option value={editValues.IdMarca} selected disabled hidden>{editValues.NomeMarca}</option>
                                        <option value="1">BMW</option>
                                        <option value="2">Chevrolet</option>
                                        <option value="3">DAF</option>
                                        <option value="4">Facchini</option>
                                        <option value="5">Ford</option>
                                        <option value="6">Guerra</option>
                                        <option value="7">Honda</option>
                                        <option value="8">Hyundai</option>
                                        <option value="9">Iveco</option>
                                        <option value="10">Jeep</option>
                                        <option value="11">Kia</option>
                                        <option value="12">Land Rover</option>
                                        <option value="13">Librelato</option>
                                        <option value="14">MAN</option>
                                        <option value="15">Mercedes-Benz</option>
                                        <option value="16">Mitsubishi</option>
                                        <option value="17">Nissan</option>
                                        <option value="18">NOMA</option>
                                        <option value="19">PASTRE</option>
                                        <option value="20">RAM</option>
                                        <option value="21">Randon</option>
                                        <option value="22">RODOFLEX</option>
                                        <option value="23">Rossetti</option>
                                        <option value="24">Scania</option>
                                        <option value="25">Toyota</option>
                                        <option value="26">Volkswagen</option>
                                        <option value="27">Volvo</option>
                                    </select>

                                    <label htmlFor="IdMarca" className="input__label">Marca</label>
                                </div>

                                <div className="input__group">
                                    <input
                                        name="AnoFabricacao"
                                        className="input__field disabled"
                                        type="text"
                                        maxLength="4"
                                        defaultValue={editValues?.AnoFabricacao}
                                        disabled
                                    />

                                    <label htmlFor="AnoFabricacao" className="input__label">Ano Fabricação</label>
                                </div>

                                <div className="input__group">
                                    <input
                                        name="AnoModelo"
                                        className="input__field disabled"
                                        type="text"
                                        maxLength="4"
                                        defaultValue={editValues?.AnoModelo}
                                        disabled
                                    />

                                    <label htmlFor="AnoModelo" className="input__label">Ano Modelo</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <input
                                        required
                                        type="text"
                                        name="Modelo"
                                        className="input__field disabled"
                                        defaultValue={editValues?.Modelo}
                                        disabled
                                    />

                                    <label htmlFor="Modelo" className="input__label">Nome</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="Direcao"
                                        className="input__field disabled"
                                        defaultValue={editValues?.Direcao}
                                        disabled
                                    >
                                        <option value="Mecânica">Mecânica</option>
                                        <option value="Hidráulica">Hidráulica</option>
                                        <option value="Elétrica">Elétrica</option>
                                    </select>

                                    <label htmlFor="Direcao" className="input__label">Direção</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <select
                                        name="Cor"
                                        className="input__field disabled"
                                        defaultValue={editValues?.Cor}
                                        disabled
                                    >
                                        <option value="Azul">Azul</option>
                                        <option value="Amarelo">Amarelo</option>
                                        <option value="Branco">Branco</option>
                                        <option value="Preto">Preto</option>
                                        <option value="Prata">Prata</option>
                                        <option value="Bordo">Bordo</option>
                                        <option value="Cinza">Cinza</option>
                                        <option value="Dourado">Dourado</option>
                                        <option value="Laranja">Laranja</option>
                                        <option value="Marrom">Marrom</option>
                                        <option value="Verde">Verde</option>
                                        <option value="Vermelho">Vermelho</option>
                                        <option value="Vinho">Vinho</option>
                                        <option value="Rosa">Rosa</option>
                                    </select>

                                    <label htmlFor="Cor" className="input__label">Cor</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="Combustivel"
                                        className="input__field disabled"
                                        defaultValue={editValues?.Combustivel}
                                        disabled
                                    >
                                        <option value="Gasolina">Gasolina</option>
                                        <option value="Álcool">Álcool</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Flex">Flex</option>
                                        <option value="Híbrido">Híbrido</option>
                                        <option value="Elétrico">Elétrico</option>
                                    </select>

                                    <label htmlFor="Combustivel" className="input__label">Combustível</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="Cambio"
                                        className="input__field disabled"
                                        defaultValue={editValues?.Cambio}
                                        disabled
                                    >
                                        <option value="Automático">Automático</option>
                                        <option value="Manual">Manual</option>
                                    </select>

                                    <label htmlFor="Cambio" className="input__label">Câmbio</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group disabled">
                                    <InputNumber
                                        name="Km"
                                        value={editValues?.Km}
                                        disabled
                                    />

                                    <label htmlFor="Km" className="input__label">KM</label>
                                </div>

                                <div className="input__group disabled">
                                    <InputNumber
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        required
                                        name="Valor"
                                        value={editValues?.Valor}
                                        disabled
                                    />

                                    <label htmlFor="Valor" className="input__label">Valor</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <textarea
                                        name="Observacao"
                                        rows={4}
                                        className="input__area disabled"
                                        placeholder="Descreva informações que serão exibidas no anúncio para ajudar a sua venda..."
                                        defaultValue={editValues?.Observacao}
                                        disabled
                                    />

                                    <label htmlFor="Observacao" className="input__label">Observação</label>
                                </div>
                            </div>

                            <div className="uploaded">
                                <div className="panel__heading">
                                    <h2>Lista de Fotos</h2>
                                </div>
                                <div className="uploaded__container">
                                    {photos.map((image, index) => (
                                        <div key={index} className="uploaded__image">
                                            <img
                                                src={image.url}
                                                alt={editValues.Modelo}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "/default-thumb.png";
                                                }}
                                            />
                                            <button className="btn__trash" style={{ cursor: "not-allowed" }} disabled><FaRegTrashCan /></button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="file__caption"><FaFile />{photos.length + " arquivos selecionado(s)"}</div>

                            {errors?.msg && (
                                <p className="error__message" style={{ color: "red" }}>{errors?.msg}</p>
                            )}

                            <div className="cadastro__button">
                                <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>Cancelar</button>
                                <button className="btn btn-danger" type="button" onClick={handleClick}>Excluir</button>
                            </div>
                        </form>
                    }

                    {!loading && editValues.length === 0 &&
                        <p style={{ display: "flex", justifyContent: "center", height: "60vh" }}>Nenhum registro encontrado!</p>
                    }
                </div>


            </div>
        </>
    )
}

export default DeletarCarro;
