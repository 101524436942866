import React from "react";
import "./Footer.scss";

import Logo from "../../assets/images/logo.png"
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer--container">
          <div className="footer--content">
            <Link to="/" onClick={() => window.scroll(0,0)} className="content--left">
              <img src={Logo} alt="Logo" />
            </Link>
            <div className="content--right">
              <div className="content--location">
                <p className="content--title">ENDEREÇO</p>
                <p className="location">BR 364 Perímetro Urbano de Jaciara,<br></br>Jaciara - MT, CEP: 78820-000</p>
              </div>
              <div className="content--contact">
                <p className="content--title">CONTATO</p>
                <Link 
                  to="https://api.whatsapp.com/send/?phone=5566999815612&text=Olá, estou interessado em adquirir um veículo" 
                  target="_blank" 
                  className="content--link"
                >
                  <FaWhatsapp />(66) 99981-5612
                </Link>
                <Link 
                  to="mailto:veiculoseciamt@gmail.com" 
                  className="content--link"
                >
                  <AiOutlineMail />veiculoseciamt@hotmail.com
                </Link>
              </div>
              <div className="content--follow">
                <p className="content--title">SIGA-NOS</p>                
                <Link 
                  to="https://www.instagram.com/veiculosecia/"
                  target="_blank" 
                  className="content--link"
                >
                  <FaInstagram />Instagram
                </Link>
                <Link 
                  to="https://pt-br.facebook.com/veiculosecia"
                  target="_blank" 
                  className="content--link"
                >
                  <FaFacebook />Facebook
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;