import React, { useEffect, useState } from "react";
import "./Estoque.scss";
import api from "../../config/configApi";
import Cards from "../../components/Cards/Cards";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import $ from "jquery";

function Estoque() {
    const [veiculos, setVeiculos] = useState({
        AnoFabricacao: "",
        AnoModelo: "",
        Cambio: "",
        Carroceria: "",
        Combustivel: "",
        Cor: "",
        CreatedAt: "",
        Direcao: "",
        IdCategoria: "",
        IdMarca: "",
        IdVeiculo: "",
        Imagem: "",
        Km: "",
        Modelo: "",
        NomeCategoria: "",
        NomeMarca: "",
        Observacao: "",
        Tipo: "",
        Tracao: "",
        Valor: ""
    });
    const [filtrado, setFiltrado] = useState({
        AnoFabricacao: "",
        AnoModelo: "",
        Cambio: "",
        Carroceria: "",
        Combustivel: "",
        Cor: "",
        CreatedAt: "",
        Direcao: "",
        IdCategoria: "",
        IdMarca: "",
        IdVeiculo: "",
        Imagem: "",
        Km: "",
        Modelo: "",
        NomeCategoria: "",
        NomeMarca: "",
        Observacao: "",
        Tipo: "",
        Tracao: "",
        Valor: ""
    });

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [anode, setAnode] = useState("");
    const [anoate, setAnoate] = useState("");

    const [isActive, setIsActive] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (location.search === "") {
            const fetch = async () => {
                setLoading(true);
                const vehicles = await getVeiculos();
                setVeiculos(vehicles);
                setLoading(false);
            }

            fetch();
        } else if (location.search !== "") {
            for (const [key, value] of searchParams.entries()) {
                if (key === "marca") {
                    $(`#marca option[value='${value}']`).prop("selected", true);
                } else if (key === "tipo") {
                    $(`#tipo option[value='${value}']`).prop("selected", true);
                } else if (key === "anode") {
                    setAnode(value);
                } else if (key === "anoate") {
                    setAnoate(value);
                }
            }

            const fetchFilter = async () => {
                setLoading(true);
                const vehicles = await getFiltrados();
                setVeiculos(vehicles);
                setLoading(false);
            }

            fetchFilter();
        }

    }, [searchParams])

    const getVeiculos = async () => {
        try {
            const response = await api.get("/api/veiculos/pegarVeiculos");
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    const getFiltrados = async () => {
        try {
            const response = await api.get("/api/veiculos/pegarFiltrados" + location.search);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    const handleClick = () => {
        setIsActive(!isActive);
        document.body.style.overflow = document.body.style.overflow == "hidden" ? "initial" : "hidden";
        window.scroll(0, 0);
        setTimeout(() => setModal(!modal), 500);
    }

    const handleClose = () => {
        setModal(!modal);
        setTimeout(() => setIsActive(!isActive), 500);
        document.body.style.overflow = document.body.style.overflow == "hidden" ? "initial" : "hidden";
    }

    function handleFilterUpdate(key, value) {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();

        let pathname = location.pathname;

        let searchParams = new URLSearchParams(location.search);

        if (key === "anode" && value.length === 4 && value >= 2000 && value <= anoAtual) {
            searchParams.set(key, value);
        } else if (key === "anoate" && value.length === 4 && value >= 2000 && value <= anoAtual) {
            searchParams.set(key, value);
        } else if (key !== "anode" && key !== "anoate") {
            searchParams.set(key, value);
        }

        navigate({
            pathname: pathname,
            search: searchParams.toString(),
        })
    }

    function handleDeleteParams() {
        for (const [key, value] of searchParams.entries()) {
            searchParams.delete(key);
        }
    }

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) e.preventDefault()
    }

    return (
        <div className="estoque">
            <div
                className="modal"
                style={isActive ? { display: "block" } : { display: "none" }}
                id={"modal"}
                onClick={(e) => e.target.id === "modal" ? handleClose() : ""}
            >
                <div className={modal ? "modal__filter move" : "modal__filter"}>
                    <div className="modal__top">
                        <h2>Filtros</h2>
                        <button
                            onClick={handleClose}
                        >
                            <IoMdClose />
                        </button>
                    </div>

                    <div className="filter__input">
                        <label htmlFor="marca">Marca</label>
                        <select
                            name="marca"
                            id="marca"
                            className="input__field"
                            onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)}
                        >
                            {location.search.indexOf("marca") === -1 &&
                                <option selected disabled hidden>Marca</option>
                            }
                            <option value="1">BMW</option>
                            <option value="2">CHEVROLET</option>
                            <option value="3">DAF</option>
                            <option value="4">FACCHINI</option>
                            <option value="5">FORD</option>
                            <option value="6">GUERRA</option>
                            <option value="7">HONDA</option>
                            <option value="8">HYUNDAI</option>
                            <option value="9">IVECO</option>
                            <option value="10">JEEP</option>
                            <option value="11">KIA</option>
                            <option value="12">LAND ROVER</option>
                            <option value="13">LIBRELATO</option>
                            <option value="14">MAN</option>
                            <option value="15">MERCEDES-BENZ</option>
                            <option value="16">MITSUBISHI</option>
                            <option value="17">NISSAN</option>
                            <option value="18">NOMA</option>
                            <option value="19">PASTRE</option>
                            <option value="20">RAM</option>
                            <option value="21">RANDON</option>
                            <option value="22">RODOFLEX</option>
                            <option value="23">ROSSETTI</option>
                            <option value="24">SCANIA</option>
                            <option value="25">TOYOTA</option>
                            <option value="26">VOLKSWAGEN</option>
                            <option value="27">VOLVO</option>
                        </select>
                    </div>

                    <div className="filter__input">
                        <label htmlFor="tipo">Tipo</label>
                        <select
                            name="tipo"
                            id="tipo"
                            className="input__field"
                            onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)}
                        >
                            {location.search.indexOf("tipo") === -1 &&
                                <option selected disabled hidden>Tipo</option>
                            }
                            <option value="1">Caminhão</option>
                            <option value="2">Carreta</option>
                            <option value="3">Carro</option>
                        </select>
                    </div>

                    <div className="filter__input">
                        <label htmlFor="ano">Ano</label>
                        <div className="ano__input">
                            <input
                                name="anode"
                                maxLength={4}
                                type="text"
                                placeholder="de"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => { handleFilterUpdate(e.target.name, e.target.value); setAnode(e.target.value); }}
                                value={anode}
                            />
                            <input
                                name="anoate"
                                maxLength={4}
                                type="text"
                                placeholder="até"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => { handleFilterUpdate(e.target.name, e.target.value); setAnoate(e.target.value); }}
                                value={anoate}
                            />
                        </div>
                    </div>

                    <div className="filter__button">
                        <button
                            onClick={() => {
                                setAnode("");
                                setAnoate("");
                                handleDeleteParams();
                                navigate({
                                    pathname: "/estoque",
                                    search: ""
                                });
                            }
                            }
                        >
                            Limpar
                        </button>
                    </div>
                </div>
            </div>
            <div className="estoque__container">
                <button
                    className="hidden__button"
                    onClick={handleClick}
                >
                    Filtros
                </button>

                <div className="filter">
                    <h2 className="filter__title">Filtros</h2>

                    <div className="filter__input">
                        <label htmlFor="marca">Marca</label>
                        <select
                            name="marca"
                            id="marca"
                            className="input__field"
                            onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)}
                        >
                            {location.search.indexOf("marca") === -1 &&
                                <option selected disabled hidden>Marca</option>
                            }
                            <option value="1">BMW</option>
                            <option value="2">CHEVROLET</option>
                            <option value="3">DAF</option>
                            <option value="4">FACCHINI</option>
                            <option value="5">FORD</option>
                            <option value="6">GUERRA</option>
                            <option value="7">HONDA</option>
                            <option value="8">HYUNDAI</option>
                            <option value="9">IVECO</option>
                            <option value="10">JEEP</option>
                            <option value="11">KIA</option>
                            <option value="12">LAND ROVER</option>
                            <option value="13">LIBRELATO</option>
                            <option value="14">MAN</option>
                            <option value="15">MERCEDES-BENZ</option>
                            <option value="16">MITSUBISHI</option>
                            <option value="17">NISSAN</option>
                            <option value="18">NOMA</option>
                            <option value="19">PASTRE</option>
                            <option value="20">RAM</option>
                            <option value="21">RANDON</option>
                            <option value="22">RODOFLEX</option>
                            <option value="23">ROSSETTI</option>
                            <option value="24">SCANIA</option>
                            <option value="25">TOYOTA</option>
                            <option value="26">VOLKSWAGEN</option>
                            <option value="27">VOLVO</option>
                        </select>
                    </div>

                    <div className="filter__input">
                        <label htmlFor="tipo">Tipo</label>
                        <select
                            name="tipo"
                            id="tipo"
                            className="input__field"
                            onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)}
                        >
                            {location.search.indexOf("tipo") === -1 &&
                                <option selected disabled hidden>Tipo</option>
                            }
                            <option value="1">Caminhão</option>
                            <option value="2">Carreta</option>
                            <option value="3">Carro</option>
                        </select>
                    </div>

                    <div className="filter__input">
                        <label htmlFor="ano">Ano</label>
                        <div className="ano__input">
                            <input
                                name="anode"
                                maxLength={4}
                                type="text"
                                placeholder="de"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => { handleFilterUpdate(e.target.name, e.target.value); setAnode(e.target.value); }}
                                value={anode}
                            />
                            <input
                                name="anoate"
                                maxLength={4}
                                type="text"
                                placeholder="até"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => { handleFilterUpdate(e.target.name, e.target.value); setAnoate(e.target.value); }}
                                value={anoate}
                            />
                        </div>
                    </div>

                    <div className="filter__button">
                        <button
                            onClick={() => {
                                setAnode("");
                                setAnoate("");
                                handleDeleteParams();
                                navigate({
                                    pathname: "/estoque",
                                    search: ""
                                });
                            }
                            }
                        >
                            Limpar
                        </button>
                    </div>
                </div>

                {loading &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "350px", width: "100%" }}>
                        <img src={spinner} alt="Loading..." />
                    </div>
                }

                {!loading && veiculos.length === 0 &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "350px", width: "100%" }}>
                        <p>Nenhum registro encontrado!</p>
                    </div>
                }


                {!loading && veiculos.length > 0 &&
                    <div style={{ width: "100%" }}>
                        <section className="cards">
                            {veiculos.map(item => (
                                <Cards key={item.IdVeiculo} {...item} />
                            ))}
                        </section>
                    </div>
                }
            </div>
        </div>
    )
}

export default Estoque;
