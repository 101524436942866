import React, { useState } from "react";
import "./Contato.scss";
import contatoBanner from "../../assets/images/Contato/truck-contato.jpg";
import formImg from "../../assets/images/Contato/truck-contato2.jpg";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import InputMask from "react-input-mask";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";

function Contato() {
    const [nome, setNome] = useState("");
    const [celular, setCelular] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    function IsEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function sendEmail(e) {
        e.preventDefault();
        if (nome === "" || celular === "" || email === "" || message === "") {
            toast("Preencha todos os campos!", {
                icon: "⚠️",
            });
            return;
        }

        const emailValid = IsEmail(email);

        if (nome !== "" && celular[14] !== "_" && emailValid !== false && message !== "") {
            const serviceId = process.env.REACT_APP_SERVICE_ID;
            const templateId = process.env.REACT_APP_TEMPLATE_ID;
            const userId = process.env.REACT_APP_USER_ID;

            const templateParams = {
                from_name: nome,
                phone: celular,
                message: message,
                email: email
            }

            emailjs.send(serviceId, templateId, templateParams, userId).then((response) => {
                toast.success("Mensagem enviada!");
                setNome("");
                setCelular("");
                setEmail("");
                setMessage("");
            }, (err) => {
                toast.error("Algo deu errado! Preencha novamente e envie!");
                console.log(err)
            })
        } else {
            toast.error("Preenchimento inválido!");
            return;
        }
    }

    return (
        <div className="contato">
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <div className="contato__banner">
                <ParallaxProvider>
                    <ParallaxBanner
                        layers={[{ image: contatoBanner, speed: -10 }]}
                    >
                        <div className="absolute inset-0 flex items-center justify-center" style={{ position: "relative", height: "600px" }}>
                            <h1 className="text-8xl text-white font-thin">Fale conosco</h1>
                        </div>
                    </ParallaxBanner>
                </ParallaxProvider>
            </div>
            <div className="contato__container">
                <div className="contato__cards">
                    <div className="contato__card">
                        <div className="card__single">
                            <span className="contato__icon"><FaMapMarkerAlt /></span>
                            <p>Endereço:</p>
                            <h3>BR 364 Perímetro Urbano de Jaciara,<br />Jaciara - MT, CEP: 78820-000</h3>
                        </div>
                    </div>
                    <div className="contato__card">
                        <div className="card__single">
                            <span className="contato__icon"><BsFillTelephoneFill /></span>
                            <p>Telefone:</p>
                            <h3>(66) 3461-7500</h3>
                        </div>
                    </div>
                    <div className="contato__card">
                        <div className="card__single">
                            <span className="contato__icon"><FaWhatsapp /></span>
                            <p>Celular:</p>
                            <h3>(66) 99981-5612</h3>
                        </div>
                    </div>
                    <div className="contato__card">
                        <div className="card__single">
                            <span className="contato__icon"><MdEmail /></span>
                            <p>E-mail:</p>
                            <h3>veiculoseciamt@gmail.com</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contato__content">
                <h1>Entrar em contato</h1>
                <div className="contato__container" style={{ display: "flex" }}>
                    <div className="contato__left">
                        <img src={formImg} alt="Caminhao" />
                    </div>
                    <div className="contato__right">
                        <form className="right__content" onSubmit={sendEmail}>

                            <div className="input__group">
                                <input
                                    type="text"
                                    name="name"
                                    className="input__field"
                                    onChange={(e) => setNome(e.target.value)}
                                    value={nome}
                                />
                                <label
                                    className="input__label"
                                    htmlFor="name"
                                >
                                    Nome
                                </label>
                            </div>

                            <div className="input__group">
                                <InputMask
                                    mask="(99) 99999-9999"
                                    name="celular"
                                    className="input__field"
                                    onChange={(e) => setCelular(e.target.value)}
                                    value={celular}
                                />
                                <label
                                    className="input__label"
                                    htmlFor="name"
                                >
                                    Celular
                                </label>
                            </div>

                            <div className="input__group">
                                <input
                                    type="text"
                                    name="email"
                                    className="input__field"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                                <label
                                    className="input__label"
                                    htmlFor="email"
                                >
                                    E-mail
                                </label>
                            </div>

                            <div className="input__group" style={{ height: "115px" }}>
                                <textarea
                                    name="message"
                                    className="input__field"
                                    placeholder="Digite sua mensagem..."
                                    rows="4"
                                    style={{ resize: "none", overflow: "auto", background: "transparent" }}
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                />

                            </div>

                            <button className="contato__btn" type="submit">Enviar</button>
                        </form>
                    </div>
                </div>
            </section>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3836.3159925142118!2d-54.982112124109!3d-15.944866326679842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9377979ef0265707%3A0xb335d5fcce66b046!2sMartelli%20Transportes!5e0!3m2!1spt-BR!2sbr!4v1705341706590!5m2!1spt-BR!2sbr" width="100%" height="600" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Contato;
