import React from "react";
import { Link } from "react-router-dom";
import "./Contatos.scss";

function Contatos() {
    return (
        <div className="fundo">
            <div className="container">
                <div 
                    class="Texto-Instagram" 
                    style={{ padding: "18px", paddingTop: "450px" }}
                >
                    @veiculosecia
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="tel://6634617500" class="Menus center-center">
                            <div 
                                style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}
                            >
                                LIGUE AGORA - FIXO
                            </div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="tel://66999815612" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>LIGUE AGORA - CELULAR</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="http://api.whatsapp.com/send?phone=5566999815612" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>ACESSE NOSSO WHATSAPP</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="https://www.instagram.com/veiculosecia/" target="_blank" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>ACESSE NOSSO INSTAGRAM</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="https://goo.gl/maps/ipBD8Up6BQzMTNDF9" target="_blank" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>ACESSE NOSSA LOCALIZAÇÃO</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="http://www.veiculoseciamt.com" target="_blank" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>VISITE NOSSO SITE</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="https://wa.me/c/556699815612" target="_blank" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>ACESSE NOSSO CATÁLOGO</div>
                        </Link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-8 col-lg-8 col-xs-12 centered" >
                        <Link to="mailto:veiculoseciamt@hotmail.com" class="Menus center-center">
                            <div style={{ background: "#FDF8D0", padding: "12px", marginBottom: "12px", width: "100%" }}>E-MAIL</div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contatos;