import React from "react";
import "./NotFound.scss";
import image404 from "../../assets/images/NotFound/404.png";  
import { MdArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";

function NotFound() {

    return (
        <div className="notfound">
            <div className="notfound__container">
                <img src={image404} alt="404" />
                <p>Página não encontrada!</p>
                <Link to="/"><MdArrowBackIos />VOLTAR</Link>
            </div>
        </div>
    )
}

export default NotFound;
