import axios from "axios";
import { createContext, useEffect, useState } from "react";
import api from "../config/configApi";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )

    const login = async (inputs) => {
        // SETAR LOCAL STORAGE
        // setCurrentUser({
        //     id:1, 
        //     email: "teste@hotmail.com", 
        //     password: "teste"
        // });

        // SETAR LOCAL STORAGE
        const res = await api.post("/api/auth/login", inputs, {
            withCredentials: true,
        });

        setCurrentUser(res.data);
    };

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);
    
    return (
        <AuthContext.Provider value={{ currentUser, login }}>
            {children}
        </AuthContext.Provider>
    );

};

