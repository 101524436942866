import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirecionar() {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser !== null) {
            navigate(`/area-usuario/${currentUser.IdUsuario}`);
        } else {
            navigate("/login");
        }
    }, [])

    return (
        <>
        </>
    )
}

export default Redirecionar;
