import React from "react";
import "./Sidebar.scss";
import logoAdm from "../../assets/images/logoAdm.png";
import { Link } from "react-router-dom";
import { BsTruckFlatbed } from "react-icons/bs";
import { PiHouseSimple, PiTruck, PiCarProfile, PiWebhooksLogo } from "react-icons/pi";
import { IoLogOutOutline } from "react-icons/io5";
import api from "../../config/configApi";

function Sidebar({ id }) {
    const handleClick = async (e) => {
        e.preventDefault();
        await api.post("/api/auth/logout", { })
        .then((res) => {
            localStorage.clear();
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }
    
    return (
        <>
            <nav className="sidebar">
                <header>
                    <div className="image__text">
                        <span className="sidebar__image">
                            <img src={logoAdm} alt="logo" />
                        </span>

                        <div className="header__text text">
                            <span className="name">Veículos e Cia</span>
                            <span className="grant">Administrador</span>
                        </div>
                    </div>
                </header>

                <div className="menu__bar">
                    <div className="menu">
                        <ul className="menu__links">
                            <li className="nav__link">
                                <Link to={`/area-usuario/${id}`}><PiHouseSimple /><span>Painel de controle</span></Link>
                            </li>
                            <li className="nav__link">
                                <Link to="/cadastrar-caminhao"><BsTruckFlatbed /><span>Cadastrar caminhão</span></Link>
                            </li>
                            <li className="nav__link">
                                <Link to="/cadastrar-carreta"><PiTruck /><span>Cadastrar carreta</span></Link>
                            </li>
                            <li className="nav__link">
                                <Link to="/cadastrar-carro"><PiCarProfile /><span>Cadastrar carro</span></Link>
                            </li>
                        </ul>
                    </div>

                    <div className="bottom__content">
                        <li className="nav__link">
                                <Link to="/" target="_blank"><PiWebhooksLogo /><span>Site</span></Link>
                            </li>
                        <li className="nav__link">
                            <button className="btn__signout" onClick={handleClick}><IoLogOutOutline /><span>Sair</span></button>
                        </li>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Sidebar;
