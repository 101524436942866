import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

function Login() {
    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    })
    const [err, setErr] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    
    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev,
            [e.target.name]: e.target.value
        }));
    }
    
    const { login } = useContext(AuthContext);

    const handleLogin = async (e) => {
        e.preventDefault();
        //login();
        
        // AUTENTICAR USUARIO
        try {
            setIsActive(!isActive);
            await login(inputs);
            setTimeout(() => navigate("/redirect"), 3000);
        } catch(err) {
            setIsActive(false);
            setErr(err.response.data);
        }
        
        // REGISTRAR NOVO USUARIO
        // try {
        //     await axios.post("http://localhost:8080/api/auth/register", inputs);
        // } catch(err) {
        //     console.log(err)
        // }
    };

    //validation fields
    const validationLogin = yup.object().shape({
        email: yup
            .string()
            .email("Email inválido")
            .required("O email é obrigatório"),
        password: yup
            .string()
            .min(8, "A senha deve ter pelo menos 8 caracteres")
            .required("A senha é obrigatória"),
    });
    
    return (
        <div className={isActive ? "login load" : "login"}>
            <div className="login__container">
                <h1 className="login__title">Login</h1>
                <Formik
                    initialValues={{}}
                    validationSchema={validationLogin}
                >
                    <Form className="login__form">
                        <div className="login__group">
                            <Field
                                className="form__field"
                                type="email"
                                name="email"
                                onChange={handleChange}
                            />

                            <label htmlFor="email" className="form__label">E-mail</label>

                            <ErrorMessage
                                component="span"
                                name="email"
                                className="form__error"
                            />
                        </div>

                        <div className="login__group">
                            <Field
                                className="form__field"
                                type="password"
                                name="password"
                                onChange={handleChange}
                            />

                            <label htmlFor="password" className="form__label">Senha</label>

                            <ErrorMessage
                                component="span"
                                name="password"
                                className="form__error"
                            />
                        </div>
                        <span className="erro">{err && err}</span>
                        <button className="button" onClick={handleLogin}>Entrar</button>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default Login;
