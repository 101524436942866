import React from "react";
import "./Cards.scss";
import api from "../../config/configApi";
import { BsTruckFlatbed, BsSpeedometer2 } from "react-icons/bs";
import { GiGearStickPattern } from "react-icons/gi";
import { TfiPaintRoller } from "react-icons/tfi";

import { Link } from "react-router-dom";

function Cards(props) {
  const { IdVeiculo, IdCategoria, IdView, NomeMarca, Tipo, AnoFabricacao, AnoModelo, Modelo, Cor, Combustivel, Cambio, Tracao, Carroceria, Km, Valor, Observacao, Imagem } = props;

  function valor() {
    let valor = Valor.toString();

    if (valor == 1) {
      valor = "Consulte";
      return valor;
    } else {
      if (valor.length === 6) {
        const a = valor.slice(0, 3);
        const b = valor.slice(3, valor.length + 1);
        valor = a.concat(".", b);
      }

      if (valor.length === 5) {
        const a = valor.slice(0, 2);
        const b = valor.slice(2, valor.length + 1);
        valor = a.concat(".", b);
      }
      return "R$ " + valor + ",00";
    }
  }

  function transformKm() {
    let km = Km.toString();

    if (km == 1) {
      km = "Consulte";
      return km;
    } else {
      if (km.length === 7) {
        const a = km.slice(0, 1);
        const b = km.slice(1, 4);
        const c = km.slice(4, km.length + 1);
        km = a.concat(".", b, ".", c);
      }

      if (km.length === 6) {
        const a = km.slice(0, 3);
        const b = km.slice(3, km.length + 1);
        km = a.concat(".", b);
      }

      if (km.length === 5) {
        const a = km.slice(0, 2);
        const b = km.slice(2, km.length + 1);
        km = a.concat(".", b);
      }
      return km + "km";
    }
  }

  const handleView = (value) => {
    api.post("/api/views/updateView/" + value)
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  if (IdCategoria === 1) {
    return (
      <Link onClick={() => handleView(IdView)} to={`/veiculo/${IdVeiculo}`} target="_blank" className="card">
        <div className="card__image">
          <img 
            src={Imagem}
            alt={Modelo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/default-thumb.png";
            }}
          />
        </div>
        <div className="card__content">
          <div className="card__top">
            <p className="card__price">{valor()}</p>
            <p className="card__year">{AnoFabricacao}/{AnoModelo}</p>
          </div>
          <div className="card__bottom">
            <p className="card__title">{NomeMarca} {Modelo}</p>
            <div className="card__info">
              <p className="card__km">
                <BsSpeedometer2 />{transformKm()}
              </p>
              <p className="card__traction">
                <BsTruckFlatbed />{Tracao}
              </p>
              <p className="card__gear">
                <GiGearStickPattern />{Cambio}
              </p>
              <p className="card__color">
                <TfiPaintRoller />{Cor}
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  } else if (IdCategoria === 2) {
    return (
      <Link onClick={() => handleView(IdView)} to={`/veiculo/${IdVeiculo}`} target="_blank" className="card">
        <div className="card__image">
          <img 
            src={Imagem} 
            alt={Modelo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/default-thumb.png";
            }}
          />
        </div>
        <div className="card__content">
          <div className="card__top">
            <p className="card__price">{valor()}</p>
            <p className="card__year">{AnoFabricacao}/{AnoModelo}</p>
          </div>
          <div className="card__bottom">
            <p className="card__title">{Tipo} {NomeMarca}</p>
            <div className="card__info">
              <p className="card__model">
                <BsTruckFlatbed />{Modelo}
              </p>
              <p className="card__color">
                <TfiPaintRoller />{Cor}
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  } else if (IdCategoria === 3) {
    return (
      <Link onClick={() => handleView(IdView)} to={`/veiculo/${IdVeiculo}`} target="_blank" className="card">
        <div className="card__image">
          <img 
            src={Imagem} 
            alt={Modelo}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/default-thumb.png";
            }}
          />
        </div>
        <div className="card__content">
          <div className="card__top">
            <p className="card__price">{valor()}</p>
            <p className="card__year">{AnoFabricacao}/{AnoModelo}</p>
          </div>
          <div className="card__bottom">
            <p className="card__title">{NomeMarca} {Modelo}</p>
            <div className="card__info">
              <p className="card__km">
                <BsSpeedometer2 />{transformKm()}
              </p>
              <p className="card__gear">
                <GiGearStickPattern />{Cambio}
              </p>
              <p className="card__color">
                <TfiPaintRoller />{Cor}
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <>







      {/* {categoria.nomeCategoria === "Caminhão" ?
        (
          <Link to={`/veiculo/${IdVeiculo}`} target="_blank" className="card">
            <div className="card__image">
              <img src={require("../../assets/images/trucks" + Imagem[0])} alt="Uploaded" />
            </div>
            <div className="card__content">
              <div className="card__top">
                <p className="card__price">R$ {transformPrice()},00</p>
                <p className="card__year">{AnoFabricacao}/{AnoModelo}</p>
              </div>
              <div className="card__bottom">
                <p className="card__title">{NomeMarca} {Modelo}</p>
                <div className="card__info">
                  <p className="card__km">
                    <BsSpeedometer2 />{transformKm()}
                  </p>
                  <p className="card__traction">
                    <BsTruckFlatbed />{Tracao}
                  </p>
                  <p className="card__gear">
                    <GiGearStickPattern />{Cambio}
                  </p>
                  <p className="card__color">
                    <TfiPaintRoller />{Cor}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <Link to={`/veiculo/${url}`} target="_blank" className="card">
            <div className="card__image">
              <img src={require("../../assets/images/trucks" + imagens[0])} alt="Uploaded" />
            </div>
            <div className="card__content">
              <div className="card__top">
                <p className="card__price">R$ {transformPrice()},00</p>
                <p className="card__year">{anoFabricacao}/{anoModelo}</p>
              </div>
              <div className="card__bottom">
                <p className="card__title">{tipo.nomeTipo} {fabricante.nomeFabricante}</p>
                <div className="card__info">
                  <p className="card__model">
                    <BsTruckFlatbed />{modelo}
                  </p>
                  <p className="card__color">
                    <TfiPaintRoller />{cor}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        )
      } */}

    </>
  )
}

export default Cards;
