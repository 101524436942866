import React, { useEffect, useState } from "react";
import "./Home.scss";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";
import api from "../../config/configApi";
import { Carousel } from "../../components/Carousel/Carousel";
import Cards from "../../components/Cards/Cards";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
// import { showOnScroll } from "../../components/Common/Helpers";

import Iveco from "../../assets/images/brands/Iveco.png";
import Librelato from "../../assets/images/brands/Librelato.jpg";
import Mercedes from "../../assets/images/brands/Mercedes.png";
import Randon from "../../assets/images/brands/Randon.jpg";
import Scania from "../../assets/images/brands/Scania.png";
import Volvo from "../../assets/images/brands/Volvo.png";

import AboutUs from "../../assets/images/truck-aboutus.png";

function Home() {
  const [veiculos, setVeiculos] = useState({
    AnoFabricacao: "",
    AnoModelo: "",
    Cambio: "",
    Carroceria: "",
    Combustivel: "",
    Cor: "",
    CreatedAt: "",
    Direcao: "",
    IdCategoria: "",
    IdMarca: "",
    IdVeiculo: "",
    IdView: "",
    Imagem: "",
    Km: "",
    Modelo: "",
    NomeMarca: "",
    Observacao: "",
    Pic: "",
    Tipo: "",
    Tracao: "",
    Valor: ""
  });
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   showOnScroll("home--models");
  //   showOnScroll("cards");
  // }, [])

  useEffect(() => {
    const getVeiculos = async () => {
      try {
        const response = await api.get("/api/veiculos/getRecomendados");
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }

    const fetchBoth = async () => {
      setLoading(true);
      const vehicles = await getVeiculos();
      setVeiculos(vehicles);
      setLoading(false);
    }

    fetchBoth();
  }, [])

  return (
    <>
      <Carousel />
      <main className="home">
        <div className="home--container">
          <div className="home--models">
            <p className="models--title">
              Conheça nossos modelos em destaque
            </p>
            <div className="models--line">
              <span className="line first"></span>
              <span className="line second"></span>
              <span className="line third"></span>
            </div>

            {loading &&
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={spinner} alt="Loading..." />
              </div>
            }

            {!loading && veiculos.length > 0 &&
              <div className="recomendados__container">
                <section className="cards">
                  {veiculos.map(item => (
                    <Cards key={item.IdVeiculo} {...item} />
                  ))}
                </section>
              </div>
            }

            {!loading && veiculos.length === 0 &&
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Nenhum registro encontrado!</p>
              </div>
            }

            <Link to="/estoque" onClick={() => window.scroll(0, 0)} className="models--button">
              Ver estoque completo <BsArrowRightShort />
            </Link>
          </div>
          <div className="home--filter">
            <p className="filter--title">Fitrar pela <span className="brand--title">Marca</span></p>
            <div className="home--brands">
              <Link to="estoque?marca=9" target="_blank" className="brand--link">
                <img src={Iveco} alt="Iveco" />
              </Link>
              <Link to="estoque?marca=13" target="_blank" className="brand--link">
                <img src={Librelato} alt="Librelato" />
              </Link>
              <Link to="estoque?marca=15" target="_blank" className="brand--link">
                <img src={Mercedes} alt="Mercedes" />
              </Link>
              <Link to="estoque?marca=21" target="_blank" className="brand--link">
                <img src={Randon} alt="Randon" />
              </Link>
              <Link to="estoque?marca=24" target="_blank" className="brand--link">
                <img src={Scania} alt="Scania" />
              </Link>
              <Link to="estoque?marca=27" target="_blank" className="brand--link">
                <img src={Volvo} alt="Volvo" />
              </Link>
            </div>
          </div>
        </div>
        <div className="home--about" id="quem-somos">
          <div className="home--container">
            <div className="about--content">
              <div className="about--info">
                <h1 className="about--title">
                  Sobre a Veículos & Cia
                </h1>
                <p className="about--description">
                  Empresa no ramo de venda de veículos com mais de 13 anos de mercado,
                  estabelecida às margens da rodovia BR 364, perímetro urbano do município
                  de JACIARA-MT. A VEÍCULOS E CIA vêm há mais de 09 anos, atuando no seguimento
                  de veículos pesados, e para melhor atendê-lo, vem inovando a cada dia.
                  Disponibilizando seus veículos em vários sites e redes sociais na internet,
                  como: Facebook, Instagram, WhatsApp e Youtube, para que você cliente receba
                  nossas ofertas. Trabalhamos em parcerias com diversas transportadoras em todo
                  o Brasil, trazendo até você as melhores condições de preços para aquisição de
                  seu veículos pesados. Trabalhamos na venda por unidades ou por lotes, sempre
                  com preço diferenciado e qualidade. Trabalhamos com vários bancos e formas de
                  financiamentos. Disponibilizamos crédito para financiamento do 1° CAMINHÃO,
                  buscamos também as melhores condições de financiamentos para essa linha de
                  crédito. Venha nos visitar, teremos o maior prazer em atender você cliente.
                </p>
              </div>
              <div className="about--images">
                <img src={AboutUs} alt="Caminhão" width="760px" />
                <div className="circle--shape">
                  <p>Mais de 13 Anos de Mercado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="instagramWrapper">
          <iframe width="100%" height="1500px" src="https://www.instagram.com/veiculosecia/embed/" scrolling="no" frameBorder="0"></iframe>
        </div>
      </main>
    </>
  )
}

export default Home;