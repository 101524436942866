import React, { useState, useEffect } from "react";
import "../../styles/Cadastro.scss";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import api from "../../config/configApi";
import { FaRegTrashCan } from "react-icons/fa6";
import { InputNumber } from "primereact/inputnumber";
import { FaFile } from "react-icons/fa";

import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";

function DeletarCarreta() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [editValues, setEditValues] = useState({
        IdUsuario: "",
        IdVeiculo: "",
        IdCategoria: "",
        NomeCategoria: "",
        IdMarca: "",
        NomeMarca: "",
        AnoFabricacao: "",
        AnoModelo: "",
        Tipo: "",
        Modelo: "",
        Direcao: "",
        Cor: "",
        Combustivel: "",
        Cambio: "",
        Tracao: "",
        Carroceria: "",
        Valor: "",
        Observacao: "",
        Pasta: "",
        Imagem: "",
        CreatedAt: ""
    })
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )

    const [errors, setErrors] = useState({
        msg: null
    });

    // USEEFFECT PARA UM UNICO VEICULO
    useEffect(() => {
        const getVeiculo = async () => {
            try {
                const response = await api.get(`/api/veiculos/pegarVeiculoId/${id}`);
                return response.data;
            } catch (err) {
                console.log(err);
                toast.error(err.response.data);
                localStorage.clear();
                setTimeout(() => navigate("/login"), 3000);
            }
        }

        const getPhotos = async (folder) => {
            try {
                const response = await Photos.getAll(folder);
                return response;
            } catch (err) {
                console.log(err);
            }
        }

        const fetchBoth = async () => {
            setLoading(true);
            const vehicle = await getVeiculo();
            const pic = await getPhotos(vehicle.Pasta);
            setEditValues(vehicle);
            setPhotos(pic);
            setLoading(false);
        }

        fetchBoth();
    }, []);

    const handleClick = async (e) => {
        e.preventDefault();
        e.currentTarget.disabled = true;

        let deuErro;

        await Photos.deletePhotos(editValues.Pasta, photos)
            .then(() => {
                deuErro = false;
            })
            .catch((err) => {
                console.log(err);
                setErrors((prev) => ({ ...prev, msg: JSON.stringify(err) }))
                deuErro = true;
            })

        if (deuErro === false) {
            await api.delete(`/api/veiculos/deleteVeiculo/${editValues.IdVeiculo}`)
                .then(res => {
                    toast.success(res.data.msg);
                    setTimeout(() => navigate(`/area-usuario/${currentUser.IdUsuario}`), 3000);
                })
                .catch(err => {
                    if (err.response) {
                        toast.error(err.response.data);
                        localStorage.clear();
                        setTimeout(() => navigate("/login"), 3000);
                    } else {
                        console.log(err);
                        toast.error("Erro: Tente novamente mais tarde!");
                    }
                })
        }
    }

    return (
        <>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <Sidebar id={currentUser.IdUsuario} />
            <div className="cadastro">
                <div className="cadastro__container">
                    <div className="cadastro__top delete">
                        <h1 className="cadastro__titulo">
                            <span><MdDelete /></span>Excluir carreta
                        </h1>
                    </div>
                    {loading &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={spinner} alt="Loading..." />
                        </div>
                    }

                    {!loading && photos.length > 0 &&
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="input__group">
                                    <input
                                        name="categoria"
                                        value={"CARRETA"}
                                        className="input__field disabled"
                                        placeholder="CARRETA"
                                        disabled
                                    />

                                    <label htmlFor="categoria" className="input__label">Categoria</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="IdMarca"
                                        className="input__field disabled"
                                        disabled
                                    >
                                        <option value={editValues.IdMarca} selected disabled hidden>{editValues.NomeMarca}</option>
                                        <option value="1">BMW</option>
                                        <option value="2">CHEVROLET</option>
                                        <option value="3">DAF</option>
                                        <option value="4">FACCHINI</option>
                                        <option value="5">FORD</option>
                                        <option value="6">GUERRA</option>
                                        <option value="7">HONDA</option>
                                        <option value="8">HYUNDAI</option>
                                        <option value="9">IVECO</option>
                                        <option value="10">JEEP</option>
                                        <option value="11">KIA</option>
                                        <option value="12">LAND ROVER</option>
                                        <option value="13">LIBRELATO</option>
                                        <option value="14">MAN</option>
                                        <option value="15">MERCEDES-BENZ</option>
                                        <option value="16">MITSUBISHI</option>
                                        <option value="17">NISSAN</option>
                                        <option value="18">NOMA</option>
                                        <option value="19">PASTRE</option>
                                        <option value="20">RAM</option>
                                        <option value="21">RANDON</option>
                                        <option value="22">RODOFLEX</option>
                                        <option value="23">ROSSETTI</option>
                                        <option value="24">SCANIA</option>
                                        <option value="25">TOYOTA</option>
                                        <option value="26">VOLKSWAGEN</option>
                                        <option value="27">VOLVO</option>
                                    </select>

                                    <label htmlFor="IdMarca" className="input__label">Marca</label>
                                </div>

                                <div className="input__group">
                                    <input
                                        name="AnoFabricacao"
                                        className="input__field disabled"
                                        type="text"
                                        maxLength="4"
                                        value={editValues?.AnoFabricacao}
                                        disabled
                                    />

                                    <label htmlFor="AnoFabricacao" className="input__label">Ano Fabricação</label>
                                </div>

                                <div className="input__group">
                                    <input
                                        name="AnoModelo"
                                        className="input__field disabled"
                                        type="text"
                                        maxLength="4"
                                        value={editValues?.AnoModelo}
                                        disabled
                                    />

                                    <label htmlFor="AnoModelo" className="input__label">Ano Modelo</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <select
                                        name="Tipo"
                                        className="input__field disabled"
                                        value={editValues?.Tipo}
                                        disabled
                                    >
                                        <option value="BITREM">BITREM</option>
                                        <option value="BITREM 9 EIXOS">BITREM 9 EIXOS</option>
                                        <option value="DOLLY">DOLLY</option>
                                        <option value="JULIETA">JULIETA</option>
                                        <option value="RODOTREM">RODOTREM</option>
                                        <option value="SEMI-REBOQUE">SEMI-REBOQUE</option>
                                        <option value="TRITREM">TRITREM</option>
                                    </select>

                                    <label htmlFor="Tipo" className="input__label">Tipo</label>
                                </div>

                                <div className="input__group">
                                    <select
                                        name="Modelo"
                                        className="input__field disabled"
                                        value={editValues?.Modelo}
                                        disabled
                                    >
                                        <option value="ALGODOEIRO">ALGODOEIRO</option>
                                        <option value="AUTO ESCOLA">AUTO ESCOLA</option>
                                        <option value="BASCULANTE">BASCULANTE</option>
                                        <option value="BAU BEBIDAS">BAU BEBIDAS</option>
                                        <option value="BAU FURGAO">BAU FURGAO</option>
                                        <option value="BAU SIDER">BAU SIDER</option>
                                        <option value="BOBINEIRO">BOBINEIRO</option>
                                        <option value="BOIADEIRO">BOIADEIRO</option>
                                        <option value="CANAVIEIRO">CANAVIEIRO</option>
                                        <option value="CARGA SECA">CARGA SECA</option>
                                        <option value="CARREGA TUDO">CARREGA TUDO</option>
                                        <option value="CAVAQUEIRA">CAVAQUEIRA</option>
                                        <option value="CEGONHA">CEGONHA</option>
                                        <option value="CEREALEIRO">CEREALEIRO</option>
                                        <option value="CEREALEIRO HOPPER">CEREALEIRO HOPPER</option>
                                        <option value="CHASSI">CHASSI</option>
                                        <option value="CONTAINER">CONTAINER</option>
                                        <option value="DOLLY">DOLLY</option>
                                        <option value="DOLLY 1 EIXO">DOLLY 1 EIXO</option>
                                        <option value="DOLLY 2 EIXO">DOLLY 2 EIXO</option>
                                        <option value="EXTENSIVA">EXTENSIVA</option>
                                        <option value="FLORESTAL">FLORESTAL</option>
                                        <option value="FRANGUEIRA">FRANGUEIRA</option>
                                        <option value="FRIGORIFICO">FRIGORIFICO</option>
                                        <option value="GAIOLA de GAS">GAIOLA de GAS</option>
                                        <option value="GRANELEIRO">GRANELEIRO</option>
                                        <option value="JULIETA ROLL ON">JULIETA ROLL ON</option>
                                        <option value="KIT SIDER">KIT SIDER</option>
                                        <option value="LAGARTIXA">LAGARTIXA</option>
                                        <option value="PORTA-CONTAINER">PORTA-CONTAINER</option>
                                        <option value="PRANCHA">PRANCHA</option>
                                        <option value="REBOQUE FLEX 2 EIXOS">REBOQUE FLEX 2 EIXOS</option>
                                        <option value="REBOQUE FLEX 3 EIXOS">REBOQUE FLEX 3 EIXOS</option>
                                        <option value="REBOQUE FLEX 4 EIXOS">REBOQUE FLEX 4 EIXOS</option>
                                        <option value="Roll ON OFF">Roll ON OFF</option>
                                        <option value="SEMI REBOQUE FLEX">SEMI REBOQUE FLEX</option>
                                        <option value="SILO">SILO</option>
                                        <option value="SUINEIRA">SUINEIRA</option>
                                        <option value="TANQUE AÇO">TANQUE AÇO</option>
                                        <option value="TANQUE ALUMINIO">TANQUE ALUMINIO</option>
                                        <option value="TANQUE GASES GLP">TANQUE GASES GLP</option>
                                        <option value="TANQUE INOX">TANQUE INOX</option>
                                        <option value="TANQUE ISOTERMICO">TANQUE ISOTERMICO</option>
                                    </select>

                                    <label htmlFor="Modelo" className="input__label">Modelo</label>
                                </div>

                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <select
                                        name="Cor"
                                        className="input__field disabled"
                                        value={editValues?.Cor}
                                        disabled
                                    >
                                        <option value="Azul">Azul</option>
                                        <option value="Amarelo">Amarelo</option>
                                        <option value="Branco">Branco</option>
                                        <option value="Preto">Preto</option>
                                        <option value="Prata">Prata</option>
                                        <option value="Bordo">Bordo</option>
                                        <option value="Cinza">Cinza</option>
                                        <option value="Dourado">Dourado</option>
                                        <option value="Laranja">Laranja</option>
                                        <option value="Marrom">Marrom</option>
                                        <option value="Verde">Verde</option>
                                        <option value="Vermelho">Vermelho</option>
                                        <option value="Vinho">Vinho</option>
                                        <option value="Rosa">Rosa</option>
                                    </select>

                                    <label htmlFor="Cor" className="input__label">Cor</label>
                                </div>

                                <div className="input__group disabled">
                                    <InputNumber
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        name="Valor"
                                        value={editValues?.Valor}
                                        disabled
                                    />

                                    <label htmlFor="Valor" className="input__label">Valor</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input__group">
                                    <textarea
                                        name="Observacao"
                                        rows={4}
                                        className="input__area disabled"
                                        value={editValues?.Observacao}
                                        placeholder="Descreva informações que serão exibidas no anúncio para ajudar a sua venda..."
                                        disabled
                                    />

                                    <label htmlFor="Observacao" className="input__label">Observação</label>
                                </div>
                            </div>

                            <div className="uploaded">
                                <div className="panel__heading">
                                    <h2>Lista de Fotos</h2>
                                </div>
                                <div className="uploaded__container">
                                    {photos.map((image, index) => (
                                        <div key={index} className="uploaded__image">
                                            <img
                                                src={image.url}
                                                alt={editValues.Modelo}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "/default-thumb.png";
                                                }}
                                            />
                                            <button className="btn__trash" style={{ cursor: "not-allowed" }} disabled><FaRegTrashCan /></button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="file__caption"><FaFile />{photos.length + " arquivos selecionado(s)"}</div>

                            {errors?.msg && (
                                <p className="error__message" style={{ color: "red" }}>{errors?.msg}</p>
                            )}

                            <div className="cadastro__button">
                                <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>Cancelar</button>
                                <button className="btn btn-danger" type="button" onClick={handleClick}>Excluir</button>
                            </div>
                        </form>
                    }

                    {!loading && editValues.length === 0 &&
                        <p style={{ display: "flex", justifyContent: "center", height: "60vh" }}>Nenhum registro encontrado!</p>
                    }
                </div>
            </div>
        </>
    )
}

export default DeletarCarreta;
