import { storage } from "../libs/firebase";
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

export const getAll = async (pathName) => {
    let list = [];
    // REFERENCIA A PASTA
    const imagesFolder = ref(storage, pathName);
    // LE OS ARQUIVOS NA PASTA
    const photoList = await listAll(imagesFolder);
    // FAZ O LOOP NOS ARQUIVOS
    for (let i in photoList.items) {
        // PEGA O LINK DIRETO PRA ACESSAR A FOTO
        let photoUrl = await getDownloadURL(photoList.items[i]);
        // PREENCHE O ARRAY
        list.push({
            name: photoList.items[i].name,
            url: photoUrl
        })
    }
    // RETORNA O ARRAY PREENCHIDO
    return list;
}

export const pathExists = async (path) => {
    let exists = false;
    const storageRef = ref(storage, path);

    try {
        exists = await storageRef.getDownloadURL();
    } catch(err) {
        exists = false;
    }

    return exists;
}

export const insert = async (path, files) => {
    let list = [];

    for(let i=0; i<files.length; i++) {
        // VERIFICANDO SE EH IMG
        if (["image/jpeg", "image/jpg", "image/png"].includes(files[i].type)) {
            
            let imageRef = ref(storage, `${path}/img-${[i]}`);

            let result = await uploadBytes(imageRef, files[i]);
            let photoUrl = await getDownloadURL(result.ref);

            list.push(photoUrl);

        } else {
            return true;
        }
    }
    return list[0];
}

export const insertEdit = async (path, files) => {
    for(let i=0; i<files.length; i++) {
        // VERIFICANDO SE EH IMG
        if (["image/jpeg", "image/jpg", "image/png"].includes(files[i].type)) {
            
            let imageRef = ref(storage, `${path}/img-${v4().substring(0, 4)}`);

            let result = await uploadBytes(imageRef, files[i]);
        } else {
            return true;
        }
    }
    return false;
}

export const deletePhotos = async (pasta, imagens) => {
    for (let i in imagens) {
        let folderRef = ref(storage, `${pasta}/${imagens[i].name}`);
        
        await deleteObject(folderRef);
    }
}

export const deletePhoto = async (nomePasta, nomeImagem) => {
    let photoRef = ref(storage, `${nomePasta}/${nomeImagem}`);
    await deleteObject(photoRef);
}