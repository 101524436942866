import React, { useRef, useState } from "react";
import "../../styles/Cadastro.scss";
import Sidebar from "../../components/Sidebar";
import { InputNumber } from "primereact/inputnumber";

import { IoIosAddCircle } from "react-icons/io";
import api from "../../config/configApi";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { v4 } from "uuid";
import * as Photos from "../../services/photos";
import spinner from "../../assets/images/ring-resize.svg";

function CadastrarCaminhao() {
    const [images, setImages] = useState();
    const [uploading, setUploading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    )

    function selectFiles() {
        fileInputRef.current.click();
    }

    function onFileSelect(e) {
        setImages(e.target.files);

        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        })

        setSelectedImages(imagesArray);
    }

    function onDragOver(e) {
        e.preventDefault();
        setIsDragging(true);
        e.dataTransfer.dropEffect = "copy";
    }

    function onDragLeave(e) {
        e.preventDefault();
        setIsDragging(false);
    }

    function onDrop(e) {
        e.preventDefault();
        setIsDragging(false);

        setImages(e.dataTransfer.files);

        const selectedFiles = e.dataTransfer.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        })

        setSelectedImages(imagesArray);
    }

    const handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) e.preventDefault()
    }

    const [status, setStatus] = useState({
        type: "",
        msg: ""
    });

    const [userForm, setUserForm] = useState({
        IdUsuario: currentUser.IdUsuario,
        categoria: 1,
        marca: "0",
        anoFabricacao: "",
        anoModelo: "",
        tipo: "0",
        modelo: "",
        direcao: "0",
        cor: "0",
        combustivel: "0",
        cambio: "0",
        tracao: "0",
        carroceria: "0",
        km: 1,
        valor: 1,
        observacao: "",
        pasta: "",
        imagem: ""
    });

    const [errors, setErrors] = useState({
        marca: null,
        anoFabricacao: null,
        anoModelo: null,
        modelo: null,
        direcao: null,
        cor: null,
        combustivel: null,
        cambio: null,
        tracao: null,
        carroceria: null,
        km: null,
        valor: null,
        observacao: null,
        image: null
    });

    const handleChangeValues = (e) => {
        setUserForm((prevValue) => ({
            ...prevValue,
            [e.target.name]: e.target.value,
        }))
    }

    const handleClick = async (e) => {
        e.preventDefault();

        let formIsValid = true;

        if (userForm.IdUsuario === null) {
            formIsValid = false;
        }

        if (userForm.marca === "0") {
            setErrors((prev) => ({ ...prev, marca: "Marca é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, marca: null }));
        }

        if (userForm.anoFabricacao.toString().length < 4) {
            setErrors((prev) => ({ ...prev, anoFabricacao: "Ano Fabricação é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, anoFabricacao: null }));
        }

        if (userForm.anoModelo.toString().length < 4) {
            setErrors((prev) => ({ ...prev, anoModelo: "Ano Modelo é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, anoModelo: null }));
        }

        if (isEmpty(userForm.modelo)) {
            setErrors((prev) => ({ ...prev, modelo: "Modelo é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, modelo: null }));
        }

        if (userForm.direcao === "0") {
            setErrors((prev) => ({ ...prev, direcao: "Direção é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, direcao: null }));
        }

        if (userForm.cor === "0") {
            setErrors((prev) => ({ ...prev, cor: "Cor é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, cor: null }));
        }

        if (userForm.combustivel === "0") {
            setErrors((prev) => ({ ...prev, combustivel: "Combustível é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, combustivel: null }));
        }

        if (userForm.cambio === "0") {
            setErrors((prev) => ({ ...prev, cambio: "Câmbio é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, cambio: null }));
        }

        if (userForm.tracao === "0") {
            setErrors((prev) => ({ ...prev, tracao: "Tração é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, tracao: null }));
        }

        if (userForm.carroceria === "0") {
            setErrors((prev) => ({ ...prev, carroceria: "Carroceria é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, carroceria: null }));
        }

        if (userForm.km === null) {
            setErrors((prev) => ({ ...prev, km: "Km é obrigatório! Deixe valor 1 para ficar como consulte." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, km: null }));
        }

        if (userForm.valor === null) {
            setErrors((prev) => ({ ...prev, valor: "Valor é obrigatório! Deixe valor R$ 1,00 para ficar como consulte." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, valor: null }));
        }

        if (isEmpty(userForm.observacao)) {
            setErrors((prev) => ({ ...prev, observacao: "Observação é obrigatório." }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, observacao: null }));
        }

        if (images === undefined) {
            setErrors((prev) => ({ ...prev, image: "Imagem é obrigatório." }));
            formIsValid = false;
        } else if (images.length > 15) {
            setErrors((prev) => ({ ...prev, image: "Selecione ate 15 imagens!!!" }));
            formIsValid = false;
        } else {
            setErrors((prev) => ({ ...prev, image: null }));
        }

        if (!formIsValid) return;

        e.currentTarget.disabled = true;

        // ENVIANDO IMAGENS PARA STORAGE DO FIREBASE
        let exists;
        let result;
        let pathName = v4().substring(0, 10);
        userForm.pasta = pathName;
        // verifica se existe pasta com o nome igual
        try {
            exists = await Photos.pathExists(pathName);
        } catch (err) {
            console.log(err)
            alert(JSON.stringify(err));
        }

        if (exists === false) {
            setUploading(true);
            // faz o envio das imagens
            try {
                result = await Photos.insert(pathName, images);
            } catch (err) {
                console.log(err);
                alert(JSON.stringify(err));
            }
            setUploading(false);

            if (result === true) {
                setErrors((prev) => ({ ...prev, image: "Algo deu errado no upload da imagem!!!" }));
            } else {
                userForm.imagem = result;
            }
        } else {
            pathName = v4().substring(0, 10);
            userForm.pasta = pathName;

            setUploading(true);
            // faz o envio das imagens
            try {
                result = await Photos.insert(pathName, images);
            } catch (err) {
                console.log(err);
                alert(JSON.stringify(err));
            }
            setUploading(false);

            if (result === true) {
                setErrors((prev) => ({ ...prev, image: "Algo deu errado no upload da imagem!!!" }));
            } else {
                userForm.imagem = result;
            }
        }

        if (result !== true) {
            await api.post("/api/veiculos/addVeiculo", userForm)
            .then((response) => {
                setStatus({ type: "success", msg: response.data.msg });
                toast.success(response.data.msg);
                setTimeout(() => navigate(`/area-usuario/${currentUser.IdUsuario}`), 3000);
            }).catch((err) => {
                if (err.response) {
                    toast.error(err.response.data);
                    localStorage.clear();
                    setTimeout(() => navigate("/login"), 3000);    
                } else {
                    setStatus({ type: "error", msg: "Erro: Tente novamente mais tarde!" });
                    toast.error("Erro: Tente novamente mais tarde!");
                }
            })
        }
    }

    return (
        <>
            <div>
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                />
            </div>
            <Sidebar id={currentUser.IdUsuario} />
            <div className="cadastro">
                <div className="cadastro__container">
                    <div className="cadastro__top">
                        <h1 className="cadastro__titulo">
                            <span><IoIosAddCircle /></span>Cadastrar caminhão
                        </h1>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="input__group">
                                <input
                                    name="categoria"
                                    value={"CAMINHAO"}
                                    className="input__field disabled"
                                    placeholder="CAMINHAO"
                                    disabled
                                />

                                <label htmlFor="categoria" className="input__label">Categoria</label>
                            </div>

                            <div className="input__group">
                                <select
                                    name="marca"
                                    className="input__field"
                                    value={userForm.marca}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Marca)</option>
                                    <option value="1">BMW</option>
                                    <option value="2">CHEVROLET</option>
                                    <option value="3">DAF</option>
                                    <option value="4">FACCHINI</option>
                                    <option value="5">FORD</option>
                                    <option value="6">GUERRA</option>
                                    <option value="7">HONDA</option>
                                    <option value="8">HYUNDAI</option>
                                    <option value="9">IVECO</option>
                                    <option value="10">JEEP</option>
                                    <option value="11">KIA</option>
                                    <option value="12">LAND ROVER</option>
                                    <option value="13">LIBRELATO</option>
                                    <option value="14">MAN</option>
                                    <option value="15">MERCEDES-BENZ</option>
                                    <option value="16">MITSUBISHI</option>
                                    <option value="17">NISSAN</option>
                                    <option value="18">NOMA</option>
                                    <option value="19">PASTRE</option>
                                    <option value="20">RAM</option>
                                    <option value="21">RANDON</option>
                                    <option value="22">RODOFLEX</option>
                                    <option value="23">ROSSETTI</option>
                                    <option value="24">SCANIA</option>
                                    <option value="25">TOYOTA</option>
                                    <option value="26">VOLKSWAGEN</option>
                                    <option value="27">VOLVO</option>
                                </select>

                                <label htmlFor="marca" className="input__label">Marca</label>
                                {errors?.marca && (
                                    <p className="error__message">{errors?.marca}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <input
                                    name="anoFabricacao"
                                    className="input__field"
                                    type="text"
                                    maxLength="4"
                                    value={userForm.anoFabricacao}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChangeValues}
                                />

                                <label htmlFor="anoFabricacao" className="input__label">Ano Fabricação</label>
                                {errors?.anoFabricacao && (
                                    <p className="error__message">{errors?.anoFabricacao}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <input
                                    name="anoModelo"
                                    className="input__field"
                                    type="text"
                                    maxLength="4"
                                    value={userForm.anoModelo}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChangeValues}
                                />

                                <label htmlFor="anoModelo" className="input__label">Ano Modelo</label>
                                {errors?.anoModelo && (
                                    <p className="error__message">{errors?.anoModelo}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <input
                                    type="text"
                                    name="modelo"
                                    className="input__field"
                                    value={userForm.modelo}
                                    onChange={handleChangeValues}
                                />

                                <label htmlFor="modelo" className="input__label">Modelo</label>
                                {errors?.modelo && (
                                    <p className="error__message">{errors?.modelo}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <select
                                    name="direcao"
                                    className="input__field"
                                    value={userForm.direcao}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Direção)</option>
                                    <option value="Mecânica">Mecânica</option>
                                    <option value="Hidráulica">Hidráulica</option>
                                    <option value="Elétrica">Elétrica</option>
                                </select>

                                <label htmlFor="direcao" className="input__label">Direção</label>
                                {errors?.direcao && (
                                    <p className="error__message">{errors?.direcao}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <select
                                    name="cor"
                                    className="input__field"
                                    value={userForm.cor}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Cor)</option>
                                    <option value="Azul">Azul</option>
                                    <option value="Amarelo">Amarelo</option>
                                    <option value="Branco">Branco</option>
                                    <option value="Preto">Preto</option>
                                    <option value="Prata">Prata</option>
                                    <option value="Bordo">Bordo</option>
                                    <option value="Cinza">Cinza</option>
                                    <option value="Dourado">Dourado</option>
                                    <option value="Laranja">Laranja</option>
                                    <option value="Marrom">Marrom</option>
                                    <option value="Verde">Verde</option>
                                    <option value="Vermelho">Vermelho</option>
                                    <option value="Vinho">Vinho</option>
                                    <option value="Rosa">Rosa</option>
                                </select>

                                <label htmlFor="cor" className="input__label">Cor</label>
                                {errors?.cor && (
                                    <p className="error__message">{errors?.cor}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <select
                                    name="combustivel"
                                    className="input__field"
                                    value={userForm.combustivel}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione o Combustível)</option>
                                    <option value="Gasolina">Gasolina</option>
                                    <option value="Álcool">Álcool</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="Flex">Flex</option>
                                    <option value="Híbrido">Híbrido</option>
                                    <option value="Elétrico">Elétrico</option>
                                </select>

                                <label htmlFor="combustivel" className="input__label">Combustível</label>
                                {errors?.combustivel && (
                                    <p className="error__message">{errors?.combustivel}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <select
                                    name="cambio"
                                    className="input__field"
                                    value={userForm.cambio}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione o Câmbio)</option>
                                    <option value="Automático">Automático</option>
                                    <option value="Manual">Manual</option>
                                </select>

                                <label htmlFor="cambio" className="input__label">Câmbio</label>
                                {errors?.cambio && (
                                    <p className="error__message">{errors?.cambio}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <select
                                    name="tracao"
                                    className="input__field"
                                    value={userForm.tracao}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Tração)</option>
                                    <option value="3/4 4x2">3/4 4x2</option>
                                    <option value="3/4 6x2">3/4 6x2</option>
                                    <option value="3/4 4x4">3/4 4x4</option>
                                    <option value="Toco 4x2">Toco 4x2</option>
                                    <option value="Toco 4x4">Toco 4x4</option>
                                    <option value="Truck 6x2">Truck 6x2</option>
                                    <option value="Traçado 6x4">Traçado 6x4</option>
                                    <option value="Traçado 6x6">Traçado 6x6</option>
                                    <option value="BiTruck 8x2">BiTruck 8x2</option>
                                    <option value="BiTruck 8x4">BiTruck 8x4</option>
                                </select>

                                <label htmlFor="tracao" className="input__label">Tração</label>
                                {errors?.tracao && (
                                    <p className="error__message">{errors?.tracao}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <select
                                    name="carroceria"
                                    className="input__field"
                                    value={userForm.carroceria}
                                    onChange={handleChangeValues}
                                >
                                    <option value="0" selected disabled hidden>(Selecione a Carroceria)</option>
                                    <option value="Cavalo Mecânico">Cavalo Mecânico</option>
                                    <option value="Aves Vivas">Aves Vivas</option>
                                    <option value="Baú Bebidas">Baú Bebidas</option>
                                    <option value="Baú Frigorífico">Baú Frigorífico</option>
                                    <option value="Baú Furgão">Baú Furgão</option>
                                    <option value="Baú Sider">Baú Sider</option>
                                    <option value="Baú Térmico">Baú Térmico</option>
                                    <option value="Betoneira">Betoneira</option>
                                    <option value="Boiadeiro">Boiadeiro</option>
                                    <option value="Bomba de Concreto">Bomba de Concreto</option>
                                    <option value="Bombeiro">Bombeiro</option>
                                    <option value="Caçamba Basculante">Caçamba Basculante</option>
                                    <option value="Caçamba Cabine Suplementar">Caçamba Cabine Suplementar</option>
                                    <option value="Cegonha">Cegonha</option>
                                    <option value="Cesto Aereo Isolado">Cesto Aereo Isolado</option>
                                    <option value="Comboio">Comboio</option>
                                    <option value="Carga Seca">Carga Seca</option>
                                    <option value="Carroceria Cabine Suplementar">Carroceria Cabine Suplementar</option>
                                    <option value="Chassis">Chassis</option>
                                    <option value="Coletor de Lixo">Coletor de Lixo</option>
                                    <option value="Conteiner 20 Pés">Conteiner 20 Pés</option>
                                    <option value="Distribuidor de Fertilizantes">Distribuidor de Fertilizantes</option>
                                    <option value="Escavadeira Valetadeira">Escavadeira Valetadeira</option>
                                    <option value="Espargidor">Espargidor</option>
                                    <option value="Food Truck">Food Truck</option>
                                    <option value="Grade Baixa">Grade Baixa</option>
                                    <option value="Gaiola de Gás">Gaiola de Gás</option>
                                    <option value="Graneleiro">Graneleiro</option>
                                    <option value="Guincho Munck">Guincho Munck</option>
                                    <option value="Grua Carroceria">Grua Carroceria</option>
                                    <option value="Perfuratriz">Perfuratriz</option>
                                    <option value="Plataforma">Plataforma</option>
                                    <option value="Plataforma Guincho">Plataforma Guincho</option>
                                    <option value="Poliguindaste">Poliguindaste</option>
                                    <option value="Roll ON OFF">Roll ON OFF</option>
                                    <option value="Silo">Silo</option>
                                    <option value="Suineira">Suineira</option>
                                    <option value="Tanque Aço">Tanque Aço</option>
                                    <option value="Tanque Inox">Tanque Inox</option>
                                    <option value="Tanque Isotérmico">Tanque Isotérmico</option>
                                    <option value="Tanque Hidro Vácuo">Tanque Hidro Vácuo</option>
                                    <option value="Tanque Pipa">Tanque Pipa</option>
                                    <option value="Tora Florestal">Tora Florestal</option>
                                    <option value="Transbordo Canavieiro">Transbordo Canavieiro</option>
                                    <option value="Trio Elétrico">Trio Elétrico</option>
                                    <option value="Varredeira">Varredeira</option>
                                </select>

                                <label htmlFor="carroceria" className="input__label">Carroceria</label>
                                {errors?.carroceria && (
                                    <p className="error__message">{errors?.carroceria}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <InputNumber
                                    name="km"
                                    onValueChange={handleChangeValues}
                                    value={userForm.km}
                                />

                                <label htmlFor="km" className="input__label">KM</label>
                                {errors?.km && (
                                    <p className="error__message">{errors?.km}</p>
                                )}
                            </div>

                            <div className="input__group">
                                <InputNumber
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    name="valor"
                                    onValueChange={handleChangeValues}
                                    value={userForm.valor}
                                />

                                <label htmlFor="valor" className="input__label">Valor</label>
                                {errors?.valor && (
                                    <p className="error__message">{errors?.valor}</p>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="input__group">
                                <textarea
                                    name="observacao"
                                    rows={4}
                                    className="input__area"
                                    onChange={handleChangeValues}
                                    maxLength="600"
                                    placeholder="Descreva informações que serão exibidas no anúncio para ajudar a sua venda..."
                                />

                                <label htmlFor="observacao" className="input__label">Observação</label>
                                {errors?.observacao && (
                                    <p className="error__message">{errors?.observacao}</p>
                                )}
                            </div>
                        </div>

                        <div className="drag">
                            <div
                                className="drag__area"
                                onDragOver={onDragOver}
                                onDragLeave={onDragLeave}
                                onDrop={onDrop}
                            >
                                {isDragging ? (
                                    <span className="select">Solte imagens aqui</span>
                                ) : (
                                    <>
                                        Arraste e solte imagens aqui ou {" "}
                                        <span className="select" role="button" onClick={selectFiles}>Selecione</span>
                                    </>
                                )}

                                <input
                                    name="image"
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={onFileSelect}
                                />
                            </div>
                            <div className="container">
                                {selectedImages.map((image, index) => (
                                    <div key={index} className="image">
                                        <img src={image} alt="upload" />
                                    </div>
                                ))}
                            </div>
                            {uploading && 
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <p>"Enviando imagens para nuvem..."</p>
                                    <img src={spinner} alt="spinner" style={{width: "20px", height: "20px", marginLeft: "10px"}} />
                                </div>                            
                            }
                            {errors?.image && (
                                <p className="error__message">{errors?.image}</p>
                            )}
                        </div>

                        <div className="cadastro__button">
                            <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>Cancelar</button>
                            <button className="btn btn-success" type="button" onClick={handleClick}>Salvar</button>
                        </div>
                    </form>
                    {status.type === "success" ? <p style={{ color: "green", margin: "1rem" }}>{status.msg}</p> : ""}
                    {status.type === "error" ? <p style={{ color: "red", margin: "1rem" }}>{status.msg}</p> : ""}
                </div>

            </div>
        </>
    )
}

export default CadastrarCaminhao;
